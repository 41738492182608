import { useState } from 'react';
import axios from 'axios';

import { Oval } from 'react-loader-spinner'


export const SignUp = () => {

    const [yourName, setYourName] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [kidName, setKidName] = useState();
    const [emailDomain, setEmailDomain] = useState('');

    const [enrolled, setEnrolled] = useState(-1);

    const onSubmit = (e) => {
        e.preventDefault();

        const data = { yourName, email, phone, kidName };

        setEnrolled(0);

        axios.post(`https://learn.amarabacus.com/api/enroll`, data)
            .then(res => {
                if (res.data.success) {
                    const words = email.split('@');
                    setEmailDomain(words[1]);

                    setYourName('');
                    setEmail('');
                    setPhone('');
                    setKidName('');

                    setEnrolled(1);
                }
            });
    }




    return (
        <div className="py-24 bg-white-300" id="signup">
            <div className="max-w-screen-xl sm:mt-14 sm:mb-14 px-6 sm:px-8 lg:px-16 mx-auto ">

                <h3 className="text-3xl text-center lg:text-4xl font-medium leading-relaxed text-black-600">
                    Бүртгүүлэх
                </h3>

                <div className="grid grid-flow-row sm:grid-flow-col grid-cols-1 sm:grid-cols-2 gap-8 py-8 my-12">
                    <div className="flex w-full justify-end">
                        <div className="h-full w-full p-4">
                            <img src="../assets/signin.png" layout="responsive"
                                quality={100}
                                height={414}
                                width={508}
                                alt="img" />
                        </div>
                    </div>
                    <div className="flex flex-col justify-center w-full lg:w-9/12">
                        {
                            enrolled === -1 ?
                                <form onSubmit={onSubmit}>
                                    <div className="mb-6">
                                        <label htmlFor="your_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Таны Овог Нэр</label>
                                        <input onChange={(e) => setYourName(e.target.value)} type="text" id="your_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-orange-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Овог Нэр" required />
                                    </div>
                                    <div className="mb-6">
                                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">И-мэйл</label>
                                        <input onChange={(e) => setEmail(e.target.value)} type="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-orange-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="email@domain.com" required />
                                    </div>
                                    <div className="mb-6">
                                        <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Утас</label>
                                        <input onChange={(e) => setPhone(e.target.value)} type="phone" id="phone" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-orange-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="123-456-7890" required />
                                    </div>
                                    <div className="mb-6">
                                        <label htmlFor="kid_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Сурагчын овог нэр</label>
                                        <input onChange={(e) => setKidName(e.target.value)} type="kid_name" id="kid_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-orange-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Овог Нэр" required />
                                    </div>

                                    <button type="submit" className="font-medium tracking-wide mt-3 py-2 px-5 sm:px-8 bg-orange-500 border border-orange-500 text-white-500 outline-none rounded-l-full rounded-r-full capitalize hover:bg-orange-500 hover:text-white-500 transition-all hover:shadow-orange">Бүртгүүлэх</button>
                                </form> :
                                enrolled === 0 ?
                                    <div class="bg-green-100 rounded-lg py-5 px-6 mb-4 text-base text-green-500" role="alert">
                                        <Oval className="bg-black-500" color="#F53855" height={40} width={40} />
                                        Түр хүлээнэ үү.
                                    </div>
                                    : <div>
                                        <div class="bg-green-100 rounded-lg py-5 px-6 mb-4 text-base text-green-500" role="alert">
                                            Бүртгэл амжилттай хийгдлээ.
                                        </div>

                                        <ul className="text-black-500 self-start list-inside ml-8">
                                            <li className="relative circle-check custom-list mb-3">
                                                5 сарын 3 ны дотор доорхи хаягаар ирж бүртгэлээ баталгаажуулна уу.
                                            </li>
                                            <li className="relative circle-check custom-list  mb-3">
                                                Бүртгэлээ баталгаажуулсан эхний 10 сурагчыг хичээлд оруулна.
                                            </li>
                                            <li className="relative circle-check custom-list mb-3">
                                                Сурагчын бэлдэх зүйл: Лаптоп буюу зөөврийн компьютер, дэвтэр, бал
                                            </li>
                                            <li className="relative circle-check custom-list mb-3">
                                                Хичээл 5 сарын 8 ны ням гаригт эхэлнэ. Цагийн сонголт: 1. 8:30 - 11:00, 2. 11:00 - 13:30
                                            </li>
                                            <li className="relative circle-check custom-list mb-3">
                                                Асуух тодруулах зүйл байвал (202) 810 2737 утсаар холбогдоорой.
                                            </li>
                                            <li className="relative circle-check custom-list mb-3">
                                                Хаяг: 944 s wakefield st #100, Arlington, VA, 22204
                                            </li>
                                            <li className="relative circle-check custom-list mb-3">
                                                Энэ заавар таны ***@{emailDomain} хаяг руу илгээгдсэн.
                                            </li>
                                        </ul>


                                    </div>
                        }



                    </div>
                </div>
            </div>

        </div>
    )
};