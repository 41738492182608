import "./App.css";

import { Layout } from "./components/Layout/Layout";
import Hero from "./components/Hero";
import Feature from "./components/Feature";
import Pricing from "./components/Pricing";
import FaqArea from "./components/FaqArea";

import { SignUp } from "./components/SignUp";

const App = () => {
  return (
    <>
      <Layout>
        <Hero />
        <Feature />
        <FaqArea />
        <Pricing />
        <SignUp />
      </Layout>
    </>
  );
};

export default App;
