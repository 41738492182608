// import Image from "next/image";
import React from "react";

const Feature = () => {
  return (
    <div className="pt-8" id="feature">
      <div className="max-w-screen-xl sm:mt-14 sm:mb-14 px-6 sm:px-8 lg:px-16 mx-auto">
        <div className="grid grid-flow-row sm:grid-flow-col grid-cols-1 sm:grid-cols-2 gap-8 py-8 my-12">
          <div className="flex w-full justify-end">
            <div className="h-full w-full p-4">
              <img src="../assets/doing_web.png" layout="responsive"
                quality={100}
                height={414}
                width={508}
                alt="img" />
            </div>
          </div>
          <div className="flex flex-col justify-center ml-auto w-full lg:w-9/12">
            <h3 className="text-center mb-5 text-3xl lg:text-4xl font-medium leading-relaxed text-black-600">
              Ач холбогдол
            </h3>
            <p className="my-2 text-black-500">
              Технологи маш хурдтай хөгжиж буй орчин үеийн хүүхдүүдээ бага наснаасаа код бичиж сургах нь тэднийг ирээдүйд амжилтанд хүргэх бэлтгэл болно.
            </p>
            <p className="my-2 text-black-500">
              Код бичих нь:
            </p>
            <ul className="text-black-500 self-start list-inside ml-8">
              <li className="relative circle-check custom-list">
                Aсуудлыг шийдвэрлэх чадварыг хөгжүүлдэг
              </li>
              <li className="relative circle-check custom-list">
                Аналитик, бүтцийн сэтгэлгээг хөгжүүлдэг
              </li>
              <li className="relative circle-check custom-list">
                Бүтээлч байдал, төсөөллийг хөгжүүлдэг
              </li>
              <li className="relative circle-check custom-list">
                Хөгжилтэй бөгөөд сонирхолтой байдлаар суралцах арга
              </li>
              <li className="relative circle-check custom-list">
                Мөрөөдлөө бий болгоход туслана
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Feature;
