import React from "react";
// import Image from "next/image";
// import ButtonPrimary from "./misc/ButtonPrimary";
import { Link as LinkScroll } from "react-scroll";

const Hero = ({
  listUser = [
    {
      name: "5-6",
      number: "Анги дүүргэлт",
      icon: "/assets/Icon/heroicons_sm-user.svg",
    },
    {
      name: "10 цаг",
      number: "Нэг сард",
      icon: "/assets/Icon/heroicons_clock.svg",
    },
    {
      name: "6-8 сар",
      number: "Нийт хугацаа",
      icon: "/assets/Icon/heroicons_flag.svg",
    },
  ],
}) => {
  return (
    <div className="pt-24" id="about">
      <div className="max-w-screen-xl px-8 xl:px-16 mx-auto" >
        <div className="grid grid-flow-row sm:grid-flow-col grid-rows-2 md:grid-rows-1 sm:grid-cols-2 gap-8 py-6 sm:py-16 ">
          <div className=" flex flex-col justify-center items-start row-start-2 sm:row-start-1">
            <h1 className="text-3xl lg:text-4xl xl:text-5xl font-medium text-black-600 leading-normal">
              Вэб хуудас хийж сурмаар байна уу?
            </h1>
            <p className="text-black-500 mt-4 mb-6">
              Вэб хуудас хийж сурангаа программ хангамжийн мэдлэгийг олж авах <strong>Вэб хөгжүүлэгч</strong> сургалтандаа та бүхнийг урьж байна. (14+)
            </p>

            <LinkScroll
                            activeClass="active"
                            to="signup"
                            spy={true}
                            smooth={true}
                            duration={1000}
                            // onSetActive={() => {
                            //     setActiveLink("signup");
                            // }}
                            className="cursor-pointer py-3 lg:py-4 px-12 lg:px-16 text-white-500 font-semibold rounded-lg bg-orange-500 hover:shadow-orange-md transition-all outline-none"
                        >
                            Бүртгүүлэх
                        </LinkScroll>



            {/* <ButtonPrimary>Бүртгүүлэх</ButtonPrimary> */}
          </div>
          <div className="flex w-full">
            <div className="h-full w-full">
              <img
                src="../assets/web_development.png"
                quality={100}
                width={612}
                height={383}
                layout="responsive"
                alt="Web development"
              />
            </div>
          </div>
        </div>
        <div className="relative w-full flex">
          <div className="rounded-lg w-full grid grid-flow-row sm:grid-flow-row grid-cols-1 sm:grid-cols-3 py-9 divide-y-2 sm:divide-y-0 sm:divide-x-2 divide-gray-100 bg-white-500 z-10">
            {listUser.map((listUsers, index) => (
              <div
                className="flex items-center justify-start sm:justify-center py-4 sm:py-6 w-8/12 px-4 sm:w-auto mx-auto sm:mx-0"
                key={index}
              >
                <div className="flex mx-auto w-40 sm:w-auto">
                  <div className="flex items-center justify-center bg-orange-100 w-12 h-12 mr-6 rounded-full">
                    <img src={listUsers.icon} className="h-6 w-6 stroke-orange-500" alt="img" />
                  </div>
                  <div className="flex flex-col">
                    <p className="text-xl text-black-600 font-bold">
                      {listUsers.number}
                    </p>
                    <p className="text-lg text-black-500">{listUsers.name}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div
            className="absolute bg-black-600 opacity-5 w-11/12 roudned-lg h-64 sm:h-48 top-0 mt-8 mx-auto left-0 right-0"
            style={{ filter: "blur(114px)" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
