import Faq from 'react-faq-component';

const data = {
  title: "",
  rows: [
    {
      title: "Программчлалын анхан шатны мэдлэгтэй байх ёстой юу?",
      content: "Үгүй. Сургалт анхан шатны сургалт учир сурагчидаас программчлалын мэдлэгтэй байхыг шаардахгүй. Программчлалын мэдлэгтэй бол хичээлийг хурдан ойлгож эзэмших давуу талтай."
    },
    {
      title: "Ямар төхөөрөмж ашиглах вэ? Гар утас эсвэл таблэт болох уу?",
      content: "Зөвхөн зөөврийн компьютер буюу лаптоп ашиглана. Ямар ч үйлдлийн систэмтэй байж болно. / WINDOWS, MAC, Ubuntu, Chromebook .../"
    },
    {
      title: "Яагаад Код бичиж сурах хэрэгтэй вэ?",
      content: "Эртнээс код бичиж эхлэх нь логик, танин мэдэхүйн ур чадвар, математикийн сэтгэлгээний хүчирхэг үндэс суурийг бүрдүүлдэг бөгөөд ирээдүйн карьерт нь туслах болно. Код бичих нь STEM-ийн бусад хичээлүүдэд бат бөх суурийг бий болгодог."
    },
    {
      title: "Хэдэн насны хүүхдэд зориулсан бэ?",
      content: "Одоогоор бид 14-18 насны хүүхдүүдэд зориулж сургалтаа бэлдээд байна."
    },
    {
      title: "Ямар хичээлүүд үзэх вэ?",
      content: "Анхан шатанд Вэб дизайний үндэс /HTML, CSS/, Вэб программчлал /JAVASCRIPT/, Алгоритм, Вэб тоглоомууд /JAVASCRIPT/, Өгөгдөлийг хадгалах, удирдах /FIREBASE,.../, Ахисан шатанд REACTJS, NodeJS, ExpressJS, MongoDB зэрэг хичээлүүд үзнэ."
    },
    {
      title: "Ямар хугацааны хөтөлбөр вэ?",
      content: "Хүүхдүүд өөрсдийн хурд, хэв маягаараа хамгийн сайн суралцдаг гэдэгт бид итгэдэг. Манай ихэнх курс 4-8 сар үргэлжлэх ч бид таны хүүхдэд дараагийн сурах сонирхолтой бүх төрлийн дээд түвшний болон сонгон суралцах олон сонголтыг санал болгоно."
    },
    {
      title: "Хичээл тэнхимээр орох уу?",
      content: "Хичээл тэнхимээр, бүтэн сайн өдөр бүр 2 цаг 30 минутаар орно. Анги дүүргэлт 5-6 хүүхэд байна."
    }
  ]
}

const FaqArea = () => {
  return (
    <>
      <div className="py-8 bg-white-500" id="faq">
        <div className="max-w-screen-xl  sm:mt-14 sm:mb-14 px-6 sm:px-8 lg:px-16 mx-auto">

          <h3 className="text-3xl lg:text-4xl text-center mb-12 font-medium leading-relaxed text-black-600">
            Асуулт хариулт
          </h3>

          <div className="my-2 text-black-500">
            <Faq data={data} 
             styles={{
              titleTextColor: "black",
              rowTitleColor: "#F53855",
              rowContentColor: "#4F5665",
              rowContentPaddingBottom: '16px',
              arrowColor: "#F53855",

            }} />


            
          </div>

        </div>

      </div>
    </>
  );
};

export default FaqArea;