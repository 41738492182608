import React from "react";
// import Image from "next/image";
// import Testimoni from "./Testimoni";
// import ButtonPrimary from "./misc/ButtonPrimary";
// import ButtonOutline from "./misc/ButtonOutline";
// import { ReactSVG } from "react-svg";

const Pricing = () => {
  return (
    <div className="">
      <div className="bg-gradient-to-b from-white-300 to-white-500 w-full py-14 pt-24" id="pricing">

        <h3 className="text-3xl lg:text-4xl text-center font-medium leading-relaxed text-black-600">
          Төлбөр
        </h3>

        <div className="max-w-screen-xl px-8 xl:px-16 mx-auto" >
          <div className="grid grid-flow-row sm:grid-flow-col grid-rows-2 md:grid-rows-1 sm:grid-cols-2 gap-8 py-6 sm:py-16 ">
            <div className=" flex  justify-center items-start row-start-2 sm:row-start-1">


              <div className="flex flex-col justify-center items-center  rounded-xl py-4 px-6 lg:px-12 xl:px-20">
                <div className="p-2 lg:p-0 mt-2 lg:mt-8">
                  <img
                    src="../assets/Free.png"
                    width={100}
                    alt="Free plan"
                    className="pb-4 hidden md:block"
                  />
                </div>
                {/* <p className="text-lg text-black-600 font-medium capitalize my-2 sm:my-7">
                  Үнэгүй
                </p> */}
                <ul className="flex flex-col list-inside pl-6 xl:pl-0 items-start justify-start text-left text-black-500 flex-grow">
                  <li className="relative check custom-list my-2">
                    1 сар / 10 цаг
                  </li>
                  <li className="relative check custom-list my-2">
                    Тэнхим
                  </li>
                  <li className="relative check custom-list my-2">
                    Бүтэн сайн өдөр
                  </li>
                </ul>
                <div className="flex flex-col w-full justify-center mb-8 flex-none mt-12">
                  <p className="text-2xl text-black-600 text-center mb-4 ">
                    250$
                  </p>
                  {/* <ButtonOutline>Select</ButtonOutline> */}
                </div>
              </div>

            </div>
            <div className="flex w-full">
              <div className="h-full w-full">
                <img
                  src="../assets/clapped_hands.png"
                  quality={100}
                  width={400}
                  layout="responsive"
                  alt="clapping hands"
                />
              </div>
            </div>
          </div>

        </div>

      </div>



      <div id="contact">
        {/* <div className=" max-w-screen-xl px-6 pb-20 sm:px-8 lg:px-16 mx-auto flex flex-col w-full text-center justify-center">
          <div className="relative w-full">
            <div className="absolute rounded-xl py-8 sm:py-14 px-6 sm:px-12 lg:px-16 w-full flex flex-col sm:flex-row justify-between items-center z-10 bg-white-500">
              <div className="flex flex-col text-left w-10/12 sm:w-7/12 lg:w-5/12 mb-6 sm:mb-0 ">
                <h5 className="text-black-600 text-xl sm:text-2xl lg:text-3xl leading-relaxed font-medium">
                  Вэб хийж, <br />
                  код бичиж сураарай.
                  Subscribe Now for <br /> Get Special Features!
                </h5>
                <p>Let's subscribe with us and find the fun.</p>
              </div>
              <ButtonPrimary>Бүртгүүлэх</ButtonPrimary>
            </div>
            <div
              className="absolute bg-black-600 opacity-5 w-11/12 roudned-lg h-60 sm:h-56 top-0 mt-8 mx-auto left-0 right-0"
              style={{ filter: "blur(114px)" }}
            ></div>
          </div>
        </div> */}
      </div>


    </div>
  );
};

export default Pricing;
