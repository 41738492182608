import React from "react";
// import { ReactSVG } from 'react-svg'

export const Footer = () => {
    return (
        <div className=" pt-12 pb-24 bg-orange-100 border-t-2 border-orange-100 ">
            <div className="max-w-screen-xl w-full mx-auto px-6 sm:px-8 lg:px-16 grid grid-rows-6 sm:grid-rows-1 grid-flow-row sm:grid-flow-col grid-cols-3 sm:grid-cols-12 gap-4">
                <div className="row-span-2 sm:col-span-4 col-start-1 col-end-4 sm:col-end-5 flex flex-col items-start ">
                    {/* <ReactSVG src="../../assets/Logo.svg" className="h-8 w-auto mb-6" />
                    <p className="mb-4">
                        <strong className="font-medium">LaslesVPN</strong> is a private
                        virtual network that has unique features and has high security.
                    </p> */}
                    {/* <div className="flex w-full mt-2 mb-8 -mx-2">
                        <div className="mx-2 bg-white-500 rounded-full items-center justify-center flex p-2 shadow-md">
                            <ReactSVG src="../../assets/Icon/facebook.svg" className="h-6 w-6" />
                        </div>
                        <div className="mx-2 bg-white-500 rounded-full items-center justify-center flex p-2 shadow-md">
                            <ReactSVG src="../../assets/Icon/twitter.svg" className="h-6 w-6" />
                        </div>
                        <div className="mx-2 bg-white-500 rounded-full items-center justify-center flex p-2 shadow-md">
                            <ReactSVG src="../../assets/Icon/instagram.svg" className="h-6 w-6" />
                        </div>
                    </div> */}
                    <p className="text-gray-400">©2022 Amar Abacus inc</p>
                </div>
                <div className=" row-span-2 sm:col-span-2 sm:col-start-7 sm:col-end-9 flex flex-col">
                    <p className="text-black-600 mb-4 font-medium text-lg">Хаяг</p>
                    <p className="text-black-500 hover:text-orange-500 cursor-pointer transition-all">944 s wakefield st #100, Arlington, VA, 22204 </p>
                    
                    {/* <ul className="text-black-500 ">
                        <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
                            Download{" "}
                        </li>
                        <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
                            Pricing{" "}
                        </li>
                        <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
                            Locations{" "}
                        </li>
                        <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
                            Server{" "}
                        </li>
                        <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
                            Countries{" "}
                        </li>
                        <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
                            Blog{" "}
                        </li>
                    </ul> */}
                </div>
                <div className="row-span-2 sm:col-span-2 sm:col-start-9 sm:col-end-11 flex flex-col">
                    <p className="text-black-600 mb-4 font-medium text-lg">Утас / И-мэйл</p>
                    <p className="text-black-500 hover:text-orange-500 cursor-pointer transition-all">+1 (571) 643 4534 </p>
                    <p className="text-black-500 hover:text-orange-500 cursor-pointer transition-all">+1 (202) 810 2737 </p>
                    <p className="text-black-500 hover:text-orange-500 cursor-pointer transition-all">info@amarabacus.com</p>

                    {/* <ul className="text-black-500">
                        <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
                            LaslesVPN ?{" "}
                        </li>
                        <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
                            FAQ{" "}
                        </li>
                        <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
                            Tutorials{" "}
                        </li>
                        <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
                            About Us{" "}
                        </li>
                        <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
                            Privacy Policy{" "}
                        </li>
                        <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
                            Terms of Service{" "}
                        </li>
                    </ul> */}
                </div>
                {/* <div className="row-span-2 sm:col-span-2 sm:col-start-11 sm:col-end-13 flex flex-col">
                    <p className="text-black-600 mb-4 font-medium text-lg">Earn Money</p>
                    <ul className="text-black-500">
                        <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
                            Affiliate{" "}
                        </li>
                        <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
                            Become Partner{" "}
                        </li>
                    </ul>
                </div> */}
            </div>
        </div>
    );
};
